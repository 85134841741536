@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.FooterInlineLinks {

    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 32px;

        p {
            margin: 0;
            font-size: 16px;
        }
    }

    ul {
        li {
            margin: 0 0 rem(24);
        }

        &.ecomInlineLinks {
            @include inlineLinksList;
        }

        &.corpInlineLinks {
            @include mobile {
                @include inlineLinksList;
            }
        }
    }
}