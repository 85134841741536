@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.navCorporate {
  position: absolute;
  top: 0;
  left: -102%;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  width: 100dvw;
  height: 100dvh;
  overflow-y: scroll;
  background: white;
  transition: left var(--menu-open-speed)  linear;
  z-index: 10;

  @include tablet {
    position: unset;
    display: block;
    width: fit-content;
    background: transparent;
    height:unset;
    overflow-y: unset !important;
  }

  &.open {
    left: 0;
    @include tablet {
      height: unset;
      width: fit-content;
      animation: none;
    }
  }

  .navCorporateHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #EAE8E7;
    padding: rem(16) rem(20);

    @include tablet {
      display: none;
    }
  }

  .modalContainer {
    padding: rem(16);
    @include tablet {
      display: none;
    }
  }
  .modalPlaceholder{
    width: 100%;
    height: 268px;
    background-color: #ccc;
  }

  .navList {
    display: flex;
    align-items: center;
    border-bottom: rem(1) solid $primary-fog-900;
    justify-content: space-between;

      .subMenuBackButton {
        outline: none;
        padding: rem(20) 0 rem(20) rem(20);
  
        svg {
          transform: rotate(0);
          fill: $primary-midnight-900;
          width: rem(16);
          height: rem(16);
        }

        @include tablet {
          padding: 0;
          svg {
            transform: rotate(90deg);
          }
        }
      }
    
    @include tablet {
      position: relative;
        button {
          padding: 0;

          svg {
            transform: rotate(90deg);
          }
        }
      &:hover {
        .showSubDropDown { 
         display: block;
       }
      }

      .showSubDropDown { 
        display: none;
      }
    }

    svg {
      transform: rotate(90deg);
      margin-top: rem(2);
      margin-left: rem(2);
    }
  }
  
  .navItems {
    display: flex;
    flex-direction: column;
    padding: 0 rem(20);
    
    @include tablet {
      padding: 0;
      gap: rem(32);
      flex-direction: row;
      align-items: center;
    }

    span {
      &.navItem {
        display: none;
        @include tablet {
          display: block;
        }
      }

      &.regionSelector {
        display: block;
        @include tablet {
          display: none;
        }
      }
    }
  }

  .navItem {
    font-size: rem(19);
    font-style: normal;
    font-weight: 700;
    color: $primary-midnight-900;
    padding: rem(24) 0 rem(23) 0;
    cursor: pointer;
    &:hover,
    &:active,
    &.active {
      text-decoration: underline;
    }

    @include tablet {
      font-size: rem(16);
      font-style: normal;
      font-weight: 700;
      padding: rem(28) 0 rem(24);
      border-bottom: rem(4) solid transparent;
      transition: all .2s ease-in-out;

      &:hover,
      &:active,
      &.active {
        // position: relative;
        //   top: 3px; 
          text-decoration: none;
          // border-bottom: 4px solid $secondary-cloud-900;
          box-shadow: 0 4px 0 0 $secondary-cloud-900;
          background-clip: padding-box;
          z-index: 5;
      }
    }

    &:focus-visible {
      outline: rem(2) solid $information-300;
      border: rem(2) solid $information-300 !important;
    }
  }
}

.modalContent {
  padding: 0 !important;
   max-width: rem(472);
    min-width: rem(400);
}

