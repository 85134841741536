@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.icon {
	width: 24px;
	height: 24px;
	fill: $primary-midnight-500;
	animation: rotate 3s linear infinite;
}

@keyframes rotate {
	0%{
	   transform: rotate(0deg);
	  }
   100%{
	   transform: rotate(360deg);
	  }
   }
